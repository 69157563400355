body {
  background-color: #f3f3f3 !important;
  overflow: hidden;
}

li {
  list-style: none;
}

a {
  text-decoration: none !important;
  color: inherit;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.App {
  text-align: center;
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ctaBtn {
  outline: none;
  border: 1px solid #13a79d;
  background-color: #13a79d;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 6px;
  font-size: 18px;
  margin-top: 10px;
}

.ctaBtn:hover {
  background-color: transparent;
  color: #13a79d;
}

.tabel_page {
  padding: 5px;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 20px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tabel_page tbody tr:nth-of-type(odd) td,
.tabel_page tbody tr:nth-of-type(odd) th {
  background-color: #f5f5f5 !important;
}

.table>tbody tr td:nth-of-type(1) {
  max-width: 150px;
  word-break: break-all;
}

.tabel_page .addCta {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 6px 20px;
  border-radius: 40px;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #13a79d;
  color: #13a79d;
  text-transform: uppercase;
}

.tabel_page .addCta i {
  padding-right: 8px;
  color: #13a79d;
  font-size: 18px;
}

.tabel_page tbody tr td img {
  max-width: 34px;
  max-height: 34px;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 2px;
}

.tabel_page tbody tr {
  align-items: center;
}

.tabel_page tbody tr td .active {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  background-color: green;
  margin: 0 auto;
}

.tabel_page tbody tr td .inactive {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  background-color: red;
  margin: 0 auto;
}

#root {
  padding-top: 0;
}



/* Pagination css starts */
.react-pagination-btm ul {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-pagination-btm ul li {
  margin: 0 6px;
  list-style: none;
}

.react-pagination-btm ul li a {
  background: #ddd;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: 700;
}

.react-pagination-btm ul li.selected a {
  background: #030619;
  color: #fff;
}

/* Pagination css ends */
.dropdown .btn[aria-expanded="true"], .show.dropdown .show.btn[aria-expanded="true"],
.dropdown .btn[aria-expanded="false"], .dropdown .btn[aria-expanded="false"] {
  border: unset;
}

.actionsIcon {
  display: flex;
  flex-wrap: wrap;
}

.actionsIcon li {
  display: inline-block;
  margin: 0 6px;
  cursor: pointer;
}

.actionsIcon li i {
  color: #13a79d;
  font-size: 18px;
}
