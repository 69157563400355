.sideBar {
  position: fixed;
  top: 84px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  width: auto;
  padding: 20px 0 20px;
}

.sideBar h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #1c1b1c;
  padding: 0 50px;
  line-height: 1;
}

.sideBar ul li a {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  padding: 12px 50px;
  cursor: pointer;
  color: #a5a5a5;
  display: inline-block;
  width: 100%;
}

.sideBar ul li a {
  color: #a5a5a5;
}

.sideBar ul li:hover a {
  color: #fff;
}

.acitve .sideBar {
  background-color: red;
}

.sideBar ul li i {
  display: inline-block;
  padding-right: 6px;
  font-size: 18px;
}

.sideBar ul li:hover {
  color: #fff;
  background: #13a79d;
}

.sideBar ul li.activeMenu a {
  color: #fff;
  background: #13a79d;
}

.sideBarOpen i {
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  cursor: pointer;
}

.xyz ul li a {
  font-size: 0;
  padding: 12px 30px;
}

.xyz .sideBarOpen .fa-angle-left {
  transform: rotate(180deg);
}
