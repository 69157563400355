.categoryBlock {
    /* background-color: #fff; */
    border-radius: 12px;
    margin-top: 20px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.categoryBlock form {
    max-width: 60%;
    margin: 0 auto;
    width: 100%;
    padding: 50px;
    border: 1px solid #dee2e6;
    border-radius: 12px;
    box-shadow: 0 0 10px #dee2e6;
    background-color: #fff;
    margin-bottom: 45px;
}

.categoryBlock .text-center {
    text-align: left !important;
}