@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.admin_dashboard {
  padding: 0 0 0;
}

.admin_dashboard h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

.admin_dashboard .inner_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.admin_dashboard .inner_row .item {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 69px;
}

.admin_dashboard .inner_row .item img {
  position: relative;
  top: -10px;
  right: -6px;
}

.admin_dashboard .inner_row .item p {
  margin-bottom: 0;
}

.main {
  padding-left: 250px;
  padding-right: 20px;
}

.main h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 15px;
  text-transform: uppercase;
}

.activeSidebar {
  padding-left: 114px;
}

@media (max-width: 767px) {
  .admin_dashboard .inner_row {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
}
