.main {
  padding-left: 260px;
}

.menu li {
  padding: 10px 5px;
}

.logo {
  padding-top: 10px;
}

.siteHeader {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.menu ul li .btn,
.menu ul li .btn:active,
.menu ul li .dropdown-toggle.show.btn {
  padding: 0;
  border: unset !important;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border: unset !important;
}
