.sign_form {
  padding: 35px 25px;
  /* box-shadow: 0 0 4px #ccc; */
  border-radius: 14px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.customRow {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_form h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}

.form-group label {
  font-weight: 600;
  font-size: 14px;
}

.form-group input {
  height: 50px;
  border: 1px solid #ddd;
}

.form-group input::placeholder {
  color: #ddd;
}

.form-group input:focus {
  box-shadow: unset;
  outline: none;
  border-color: #000;
}

.sign_block {
  background-color: #13a79d26;
}
